<template>
  <ul v-if="page !== null && totalItems !== null && totalItems > first" class="pagination">
    <li v-if="page !== 1">
      <button class="pagination__btn" type="button" @click="$emit('paginate', disabled ? page : 1)">
        <IconComponent name="d-arrow-left" />
      </button>
    </li>
    <li v-if="page !== 1">
      <button
        class="pagination__btn pagination__btn--hide"
        type="button"
        @click="$emit('paginate', disabled ? page : current[0])"
      >
        <IconComponent name="arrow-left-fill" />
      </button>
    </li>
    <li v-for="(p, i) in current" :key="i">
      <button
        :class="{ 'pagination__btn--active': page === p }"
        class="pagination__btn"
        type="button"
        @click="$emit('paginate', disabled ? page : p)"
      >
        {{ p }}
      </button>
    </li>
    <li v-if="page !== total">
      <button
        class="pagination__btn pagination__btn--hide"
        type="button"
        @click="$emit('paginate', disabled ? page : current[current.length - 1])"
      >
        <IconComponent name="arrow-right-fill" />
      </button>
    </li>
    <li v-if="page !== total">
      <button class="pagination__btn" type="button" @click="$emit('paginate', disabled ? page : total)">
        <IconComponent name="d-arrow-right" />
      </button>
    </li>
  </ul>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    total: Number,
    totalItems: Number,
    first: Number,
    disabled: Boolean,
  },
  computed: {
    current() {
      let startPage, endPage;
      let maxPages = 3;
      if (this.total <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = this.total;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (this.page + maxPagesAfterCurrentPage >= this.total) {
          // current page near the end
          startPage = this.total - maxPages + 1;
          endPage = this.total;
        } else {
          // current page somewhere in the middle
          startPage = this.page - maxPagesBeforeCurrentPage;
          endPage = this.page + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.pagination {
  display flex
  //justify-content center
  align-items center
  list-style none
  gap 5px

  &__btn {
    background: var(--white);
    border-radius: 100px;
    width 50px
    height 50px
    display flex
    justify-content center
    align-items center
    border 1px solid var(--gray-dark)
    box-sizing border-box
    font-weight 500
    font-size: 1em;
    line-height: 26px;
    text-align: center;
    outline none
    color: var(--dark);
    cursor pointer

    &--hide {
      +below(1024px) {
        display none
      }
    }

    &--active {
      background var(--gray-dark)
      border: 1px solid var(--gray-dark);
      color var(--dark-light)
    }

    &:hover:not(&--active) {
      background var(--main)
      border: 1px solid var(--main);
      color var(--white)

      .icon svg path {
        fill var(--white)
      }
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--dark)
      }
    }
  }
}
</style>
